<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading event list
  </loading-spinner>
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search event by title or topic"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div class="ms-2" style="width: 10rem">
        <select class="form-select" v-model="filterStatus">
          <option :value="null">All</option>
          <option value="Published">Published</option>
          <option value="Draft">Draft</option>
        </select>
      </div>
    </div>
    <div
      class="
        pb-3
        d-flex
        justify-content-between
        align-items-center
        flex-column flex-sm-row
      "
    >
      <div>
        <span class="badge bg-dark shadow-sm py-2 fw-normal">
          Total Events: {{ events.length }}
        </span>
        <span class="badge bg-success shadow-sm py-2 ms-2 fw-normal">
          Published:
          {{ events.filter((event) => event.status == "Published").length }}
        </span>
        <span
          class="badge bg-light border text-dark shadow-sm py-2 ms-2 fw-normal"
        >
          Draft:
          {{ events.filter((event) => event.status == "Draft").length }}
        </span>
      </div>
      <button
        class="btn btn-sm btn-primary px-3 mt-2 mt-sm-0"
        @click="viewEvent()"
      >
        <i class="fas fa-plus me-2" />
        Add event
      </button>
    </div>
    <div
      class="
        table-responsive
        rounded
        shadow-sm
        rounded
        border border-light
        position-relative
      "
    >
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th class="pe-0" style="width: 1%"></th>
            <th class="pe-0">Title</th>
            <th class="pe-0">Date</th>
            <th class="text-center px-0">Users</th>
            <th class="text-center">Price</th>
            <th class="text-center ps-0">Status</th>
            <th class="text-center px-0">Links</th>
            <th class="text-center">Stream</th>
            <th class="ps-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in computedEvents" :key="event._id">
            <td class="pe-0" style="width: 1%">
              <img
                :src="Helper.formatMediaUrl(event.poster)"
                style="height: 20px; width: 20px"
                class="shadow-sm rounded clickable"
                v-if="Helper.formatMediaUrl(event.poster)"
                @click.stop="currentMedia = event.poster"
              />
            </td>
            <td class="text-nowrap text-truncate pe-0">
              <small class="d-block text-muted">{{ event.topic }}</small>
              <button
                class="
                  btn btn-link
                  p-0
                  w-100
                  clickable
                  text-start text-decoration-none text-truncate
                "
                @click="viewEvent(event)"
              >
                {{ event.title }}
              </button>
            </td>
            <td class="text-nowrap text-truncate pe-0">
              <small class="d-block">
                {{ Helper.formatDate(event.start, true) }}
              </small>
              <small>
                {{ Helper.formatDate(event.end, true) }}
              </small>
            </td>
            <td class="text-center px-0" style="width: 1%">
              <span class="badge bg-light border text-dark fw-normal p-2">
                {{ event.users ? event.users.length : 0 }}
              </span>
            </td>
            <td class="text-nowrap text-truncate text-center">
              {{ Helper.formatMoney(event.price) }}
            </td>
            <td
              style="width: 1%"
              class="text-nowrap text-truncate text-center ps-0"
              :class="
                event.status == 'Published' ? 'text-success' : 'text-muted'
              "
            >
              {{ event.status }}
            </td>
            <td
              class="text-nowrap text-truncate text-center px-0"
              style="width: 1%"
            >
              <a
                :href="event.fb_link"
                target="_blank"
                class="mx-1"
                v-if="event.fb_link"
              >
                <img src="@/assets/icons/facebook.png" style="height: 20px" />
              </a>
              <a
                :href="event.ig_link"
                target="_blank"
                class="mx-1"
                v-if="event.ig_link"
              >
                <img src="@/assets/icons/instagram.png" style="height: 20px" />
              </a>
              <a
                :href="event.onpay_link"
                target="_blank"
                class="mx-1"
                v-if="event.onpay_link"
              >
                <img src="@/assets/icons/onpay.png" style="height: 20px" />
              </a>
            </td>
            <td class="text-nowrap text-truncate text-center">
              <pre class="mb-0">{{ event.stream_key }}</pre>
            </td>
            <td class="text-nowrap text-truncate text-center ps-0">
              <router-link
                @click.stop
                class="btn p-0"
                :to="{ name: 'EventDetails', params: { id: event._id } }"
                target="_blank"
                title="Go to session page"
                v-if="event.topic && event.topic.toLowerCase() != 'competition'"
              >
                <i class="fas fa-tv" />
              </router-link>
            </td>
          </tr>
          <tr v-if="computedEvents.length < 1">
            <td colspan="9" class="text-center fst-italic text-muted">
              No event found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
    <manage-event-form
      v-if="showEventModal"
      :event="currentEvent"
      @close="closeEventModal"
      @update="updateEventDetails"
      @delete="closeEventModal($event, 'delete')"
      @add="closeEventModal($event, 'add')"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";
import ManageEventForm from "@/components/Modals/ManageEventForm";

export default {
  components: { LoadingSpinner, MediaViewer, ManageEventForm },
  data() {
    return {
      isLoading: false,
      events: [],
      search: "",
      currentMedia: null,
      currentEvent: null,
      showEventModal: false,
      filterStatus: null,
    };
  },
  computed: {
    computedEvents() {
      const search = this.search.toLowerCase();

      let result = [...this.events];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter(
        (enquiry) =>
          enquiry.title.toLowerCase().includes(search) ||
          enquiry.topic.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    updateEventDetails(event) {
      if (event) {
        this.currentEvent = event;

        let eventIndex = this.events.findIndex((w) => w._id == event._id);

        if (eventIndex > -1) {
          this.$set(this.events, eventIndex, event);
        }
      }
    },
    viewEvent(event) {
      this.currentEvent = event;

      this.showEventModal = true;
    },
    closeEventModal(event, type) {
      this.currentEvent = null;

      if (event) {
        if (type == "add") {
          this.events.push(event);

          this.events.sort((a, b) => {
            return new Date(b.start) - new Date(a.start);
          });
        } else {
          const eventIndex = this.events.findIndex((w) => w._id == event._id);

          if (eventIndex > -1) {
            if (type == "delete") {
              this.events = this.events.filter((w) => w._id != event._id);
            } else {
              this.$set(this.events, eventIndex, event);
            }
          }
        }
      }

      this.showEventModal = false;
    },
    getEvents() {
      this.isLoading = true;

      this.API.get("webinars?_limit=-1&status_ne=Deleted&_sort=start:DESC")
        .then((retVal) => {
          this.events = retVal.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getEvents();
  },
};
</script>