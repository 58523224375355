<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div
      id="custom-modal-body bg-transparent"
      class="custom-modal-body shadow"
      style="max-width: 300px"
    >
      <div class="bg-primary text-light text-center p-3 position-relative">
        {{ title || "Select Date" }}
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="close('cancelled')"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <v-date-picker
        class="rounded-0"
        is-expanded
        v-model="model"
        :mode="noTime ? 'date' : 'dateTime'"
        trim-weeks
        :minute-increment="5"
      />
      <button
        class="btn btn-dark btn-lg w-100"
        style="border-top-left-radius: 0; border-top-right-radius: 0"
        @click="close('changed')"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["date", "title", "noTime"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    date: {
      handler() {
        this.model = this.date;
      },
      immediate: true,
    },
  },
  methods: {
    close(type) {
      if (this.$el.parentElement) {
        this.$el.parentElement.classList.remove("overflow-hidden");
      }

      this.$emit(type, this.model);
    },
  },
  mounted() {
    if (this.$el.parentElement) {
      this.$el.parentElement.classList.add("overflow-hidden");
    }
  },
};
</script>