<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div
      id="custom-modal-body"
      class="custom-modal-body shadow position-relative"
    >
      <div
        class="
          p-3
          bg-primary
          lead
          text-center
          fw-medium
          text-light
          position-relative
        "
      >
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="left: 0; top: 0"
          @click="deleteEvent()"
          v-if="event"
        >
          <i class="fa fa-trash-alt text-danger" />
        </button>
        {{ event ? "Edit" : "Add" }} event
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="closePreview()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="p-4">
        <div class="row row-cols-1 row-cols-md-2">
          <div class="col">
            <div class="mb-5 mb-md-3 position-relative">
              <label for="poster" class="form-label">Poster</label>
              <div
                class="ratio ratio-1x1 rounded shadow-sm border mx-auto"
                style="width: 220px"
              >
                <button
                  class="
                    btn
                    bg-light
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    p-0
                  "
                  @click="selectPoster()"
                >
                  <template v-if="models.poster || posterPreview">
                    <img
                      v-if="posterPreview"
                      class="image-fit mh-100"
                      :src="posterPreview"
                    />
                    <img
                      v-else-if="models.poster"
                      class="image-fit mh-100"
                      :src="Helper.formatMediaUrl(models.poster)"
                    />
                    <button
                      class="
                        btn btn-link
                        text-danger text-decoration-none
                        position-absolute
                      "
                      style="bottom: -35px"
                      @click.stop="clearPreview()"
                    >
                      <i class="fas fa-times me-2" />Remove
                    </button>
                  </template>
                  <template v-else>
                    <i class="fas fa-plus mb-2" />
                    <small class="font-monospace text-center lh-sm">
                      Add poster image
                    </small>
                  </template>
                </button>
              </div>
            </div>
            <div class="mb-3">
              <label for="title" class="form-label">
                Title<sup class="text-danger">*</sup>
              </label>
              <textarea
                type="text"
                class="form-control no-resize"
                id="title"
                rows="3"
                v-model="models.title"
              ></textarea>
            </div>
          </div>
          <div class="col">
            <div class="row g-3 mb-3">
              <div class="col">
                <label for="topic" class="form-label">
                  Topic<sup class="text-danger">*</sup>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="topic"
                  v-model="models.topic"
                />
              </div>
              <div class="col">
                <label for="price" class="form-label">
                  Price<sup class="text-danger">*</sup>
                </label>
                <div class="input-group">
                  <span class="input-group-text">RM</span>
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    @input="models.price = models.price.replace(/[^\d.]/g, '')"
                    v-model="models.price"
                  />
                </div>
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-lg-6">
                <label for="start" class="form-label">
                  Start<sup class="text-danger">*</sup>
                </label>
                <div
                  class="input-group cursor-pointer"
                  @click="
                    showDatePicker = {
                      for: 'start',
                      date: models.start,
                      title: 'Select start date',
                    }
                  "
                >
                  <div class="input-group-text">
                    <i class="fas fa-calendar-alt" />
                  </div>
                  <div class="form-control">
                    {{ Helper.formatDate(models.start, true) || "\&nbsp;" }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <label for="end" class="form-label">
                  End<sup class="text-danger">*</sup>
                </label>
                <div
                  class="input-group cursor-pointer"
                  @click="
                    showDatePicker = {
                      for: 'end',
                      date: models.end,
                      title: 'Select end date',
                    }
                  "
                >
                  <div class="input-group-text">
                    <i class="fas fa-calendar-alt" />
                  </div>
                  <div class="form-control">
                    {{ Helper.formatDate(models.end, true) || "\&nbsp;" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col">
                <label for="status" class="form-label">
                  Status<sup class="text-danger">*</sup>
                </label>
                <select class="form-select" v-model="models.status">
                  <option value="Draft">Draft</option>
                  <option value="Published">Published</option>
                </select>
              </div>
              <div class="col">
                <label for="stream_key" class="form-label">Stream Key</label>
                <input
                  type="text"
                  class="form-control"
                  id="stream_key"
                  v-model="models.stream_key"
                />
              </div>
            </div>
            <div class="row g-3 mb-3">
              <div class="col-12 col-md-6">
                <label for="fb_link" class="form-label">FB Link</label>
                <input
                  type="text"
                  class="form-control"
                  id="fb_link"
                  v-model="models.fb_link"
                />
              </div>
              <div class="col-12 col-md-6">
                <label for="ig_link" class="form-label">IG Link</label>
                <input
                  type="text"
                  class="form-control"
                  id="ig_link"
                  v-model="models.ig_link"
                />
              </div>
            </div>
            <div>
              <label for="onpay_link" class="form-label">OnPay Link</label>
              <input
                type="text"
                class="form-control"
                id="onpay_link"
                v-model="models.onpay_link"
              />
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light shadow" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-primary" @click="submitChange()">
            <i class="fas fa-save me-2" />
            Submit
          </button>
        </div>
        <template v-if="event">
          <hr class="my-4" />
          <div
            class="mb-3 py-2 d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">
              Booked Users
              <span class="badge bg-light border text-dark fw-normal py-2 px-3 ms-2">
                {{ event.users ? event.users.length : 0 }}
              </span>
            </h5>
            <button
              class="btn btn-sm btn-primary px-3"
              @click="showSelectUser = true"
            >
              <i class="fas fa-plus me-2" />
              Add user
            </button>
          </div>
          <div
            class="
              table-responsive
              rounded
              shadow-sm
              rounded
              border border-light
            "
          >
            <table class="table lh-sm table-admin w-100">
              <thead>
                <tr>
                  <th class="pe-0">#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(user, index) in eventUsers"
                  :key="user._id"
                  :ref="`user_${user._id}`"
                >
                  <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
                  <td class="text-nowrap text-truncate">
                    {{ user.name }}
                  </td>
                  <td class="text-nowrap text-truncate">
                    {{ user.email }}
                  </td>
                  <td class="text-nowrap text-truncate" style="width: 1%">
                    {{ user.phone }}
                  </td>
                  <td class="text-nowrap text-center" style="width: 1%">
                    <button class="btn btn-sm" @click="removeUser(user)">
                      <i class="fas fa-minus-circle text-danger" />
                    </button>
                  </td>
                </tr>
                <tr v-if="eventUsers.length < 1">
                  <td colspan="5" class="text-center fst-italic text-muted">
                    No user found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <loading-spinner class="text-light position-absolute" v-if="isLoading" />
      <select-user
        @close="closeSelectUser"
        v-if="showSelectUser"
        :disable="eventUsers"
      />
    </div>
    <date-picker
      v-if="showDatePicker.for"
      :date="showDatePicker.date"
      :title="showDatePicker.title"
      @changed="closeDatePicker"
      @cancelled="closeDatePicker()"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import DatePicker from "@/components/Modals/DatePicker";
import SelectUser from "@/components/Modals/SelectUser";

export default {
  props: ["event"],
  components: {
    LoadingSpinner,
    DatePicker,
    SelectUser,
  },
  data() {
    return {
      isLoading: false,
      showSelectUser: false,
      eventUsers: [],
      showDatePicker: {
        for: null,
        date: null,
        title: null,
      },
      posterPreview: null,
      posterInput: null,
      models: {
        title: null,
        start: null,
        end: null,
        topic: null,
        price: 0,
        poster: null,
        status: "Draft",
        ig_link: null,
        fb_link: null,
        onpay_link: null,
        stream_key: null,
      },
    };
  },
  computed: {
    formInvalid() {
      if (
        !this.models.title ||
        this.models.title.length < 4 ||
        !this.models.start ||
        !this.models.end ||
        !this.models.topic ||
        typeof this.models.price == "undefined" ||
        !this.models.status
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async closeSelectUser(e) {
      this.showSelectUser = false;

      if (e) {
        this.isLoading = true;

        try {
          this.eventUsers.push(e);

          const { data } = await this.API.put(`webinars/${this.event._id}`, {
            users: this.eventUsers,
          });

          this.$emit("update", data);
        } catch (error) {
        } finally {
          this.isLoading = false;
        }
      }
    },
    removeUser(user) {
      Swal.fire({
        title:
          "<h5 class='mb-0'>Remove this user from event's booking list?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let eventUsers = this.eventUsers.filter((u) => u._id != user._id);

          this.isLoading = true;

          try {
            const { data } = await this.API.put(
              `webinars/${this.event._id}`,
              {
                users: eventUsers,
              }
            );

            this.eventUsers = eventUsers;

            this.$emit("update", data);
          } catch (error) {
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    clearPreview() {
      this.posterInput = null;

      this.posterPreview = null;

      this.models.poster = null;
    },
    posterChanged(e) {
      let file = e.target.files[0];

      this.posterInput = file;

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (readerEvent) => {
        this.posterPreview = readerEvent.target.result;
      };
    },
    selectPoster() {
      let input = document.createElement("input");

      input.type = "file";

      input.accept = "image/*";

      input.onchange = this.posterChanged;

      input.click();
    },
    deleteEvent() {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this event?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "bg-danger",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            return this.API.put(`webinars/${this.event._id}`, {
              status: "Deleted",
            })
              .catch((error) => {})
              .finally(() => {
                Swal.fire("Event deleted", "", "success");

                this.$emit("delete", this.event);
              });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        });
    },
    closeDatePicker(date) {
      if (date) {
        this.models[this.showDatePicker.for] = date;
      }

      this.showDatePicker = {
        for: null,
        date: null,
        title: null,
      };
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    async submitChange() {
      if (this.formInvalid) {
        Swal.fire("", "Invalid form submitted", "error");
      } else {
        this.isLoading = true;

        let method = "post";

        let url = "webinars";

        let body = {};

        try {
          if (this.event) {
            if (this.posterPreview) {
              let fileModels = {
                ref: "webinar",
                refId: this.event._id,
                field: "poster",
                files: this.posterInput,
              };

              const formData = new FormData();

              Object.keys(fileModels).forEach((key) => {
                if (key != "files") {
                  formData.append(key, fileModels[key]);
                } else {
                  formData.append(
                    "files",
                    fileModels.files,
                    fileModels.files.name
                  );
                }
              });

              const { data } = await this.API.postForm("upload", formData);

              this.models.poster = data;
            }

            method = "put";

            url += `/${this.event._id}`;

            body = { ...this.models };

            if (body.poster) {
              body.poster = body.poster._id;
            }
          } else {
            if (this.posterPreview) {
              const formData = new FormData();

              formData.append("files", this.posterInput, this.posterInput.name);

              const { data } = await this.API.postForm("upload", formData);

              this.models.poster = data;
            }

            body = { ...this.models };
          }

          const { data } = await this.API[method](url, body);

          Swal.fire(
            `Event ${this.event ? "updated" : "added"}`,
            "",
            "success"
          ).then(() => {
            if (this.event) {
              this.closePreview(data);
            } else {
              this.$emit("add", data);
            }
          });
        } catch (err) {
          Swal.fire(
            "Opps. An error occured",
            JSON.stringify(err.response.data),
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    if (this.event) {
      for (const key in this.models) {
        if (Object.hasOwnProperty.call(this.models, key)) {
          this.models[key] = this.event[key];
        }
      }

      this.eventUsers = this.event.users || [];
    }
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>