<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div id="custom-modal-body bg-transparent" class="custom-modal-body shadow">
      <div class="bg-primary text-light text-center p-3 position-relative">
        Select user
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="close()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="input-group py-2">
        <span class="input-group-text bg-white border-0">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search by name, email or phone"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light border-0"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div
        class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        "
      >
        <table class="table lh-sm table-admin table-hover w-100">
          <thead>
            <tr>
              <th class="pe-0">#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in computedUsers"
              :key="user._id"
              @click="selectUser(user)"
              :class="{
                'bg-light cursor-not-allowed semi-transparent':
                  disable && disable.find((d) => d._id == user._id),
              }"
            >
              <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
              <td class="text-nowrap text-truncate">
                {{ user.name }}
              </td>
              <td class="text-nowrap text-truncate">
                {{ user.email }}
              </td>
              <td class="text-nowrap text-truncate" style="width: 1%">
                {{ user.phone }}
              </td>
            </tr>
            <tr v-if="computedUsers.length < 1">
              <td colspan="4" class="text-center fst-italic text-muted">
                No user found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading-spinner class="position-absolute text-light py-5" v-if="isLoading">
      Loading user list...
    </loading-spinner>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: { LoadingSpinner },
  props: ["disable"],
  data() {
    return {
      users: [],
      isLoading: false,
      search: "",
    };
  },
  computed: {
    computedUsers() {
      const search = this.search.toLowerCase();

      return this.users.filter(
        (user) =>
          user.name && user.name.toLowerCase().includes(search) ||
          user.email.toLowerCase().includes(search) ||
          (user.phone && user.phone.includes(search))
      );
    },
  },
  methods: {
    selectUser(user) {
      if (!this.disable || !this.disable.find((d) => d._id == user._id)) {
        Swal.fire({
          title: "<h5>Select this user?</h5>",
          html: `<div class="alert alert-info mb-0">${user.name}</div>`,
          icon: "question",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.close(user);
          }
        });
      }
    },
    async getUsers() {
      this.isLoading = true;

      try {
        const { data } = await this.API.get(
          "users?_limit=-1&role.name=Authenticated&blocked_ne=true&name_ne=DEMO ACCOUNT"
        );

        this.users = data;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    close(e) {
      document.body.classList.remove("overflow-hidden");

      this.$emit("close", e);
    },
  },
  mounted() {
    document.body.classList.add("overflow-hidden");

    this.getUsers();
  },
};
</script>